@import "common";
@include mobile {
  #top .linkItem {
    margin: 0;
    padding-top: 13.33333vw;
    padding-bottom: 18.33333vw;
    background: #F5F4ED;
  }
  #top .ItemEvent #event, #top .ItemEvent #new-item {
    border-bottom: vw-sp(2px) dotted #707070;
    a {
      span {
        font-size: vw-sp(30px);
      }
    }
  }
  #top footer {
    margin-top: 0;
  }
  #top .slick-slide img{
    width:100%;
  }
  .items-showbanner {
    position: relative;
    .item_showbanner {
      border-bottom: 2px solid #ffffff;
      position: relative;
      .text_show_banner {
        div {
          min-width: vw-sp(600px);
        }
      }
      .banner_img {
        position: relative;
        z-index: 9;
        background: none !important;
      }
      img {
        width: 100%;
      }
      .text_show_banner {
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        z-index: 10;
        margin: 0 auto;
        right: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        .page-title {
          color: #fff;
          text-shadow: 0 vw-sp(3px) vw-sp(6px) rgba(0,0,0,0.5);
        }
        p {
          font-size: vw-sp(30px);
          letter-spacing: 0.32em;
        }
        a {
          border: vw-sp(2px) solid #fff;
          border-radius: vw-sp(5px);
          height: vw-sp(85px);
          line-height: vw-sp(85px);
          margin-top: vw-sp(30px);
          background: rgba(0, 0, 0, 0.5);
          font-size: vw-sp(30px);
          font-weight: bold;
          letter-spacing: 0.1em;
          position: relative;
          &:after {
            content: '';
            height: 20px;
            width: 13px;
            background: url(../img/shop/icon_shoplist1.png) no-repeat center center;
            background-size: 100%;
            display: inline-block;
            z-index: 9;
            position: absolute;
            right: 32px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .showbanner_img,
  #new-item{
    margin:vw-sp(140px) auto 0;
  }
  .introduce_pro{
    .page-title{
      margin-top: vw-sp(90px);
    }
  }
  .page-title{

    font-size: 5vw;
    letter-spacing: 0.25em;
    line-height: vw-sp(80px);
  }
  .page-head-text{
    margin: 4.5vw auto 0;
    margin-top: vw-sp(90px);
    font-size: vw-sp(40px);
    line-height: vw-sp(80px);
  }
  #new-item .picture-column{
    width:100%;
    margin-right:0;
    margin-bottom:5.33vw;
    float:none;
  }
  #new-item .text-column{
    width:100%;
    float:none;
    margin-bottom:5.33vw;
  }
  .introduce_pro {
    padding: 0 vw-sp(85px);
  }
  .info_news .title_news {
    border-top: vw-sp(2px) solid #707070;
    border-bottom: vw-sp(2px) solid #707070;
    padding: vw-sp(10px) 0;
    text-align: center;
    position: relative;
    margin-top: vw-sp(90px);
    h2 {
      color: #000;
      line-height: 1.2;
      letter-spacing: 0.41em;
    }
    a {
      border: vw-sp(2px) solid #707070;
      color: #000;
      font-size: vw-sp(20px);
    }
  }
  .info_news {
    .title_news {
      margin-bottom: vw-sp(50px);
    }
    .news_list {
      a {
        padding: vw-sp(10px);
      }
    }
    .content-news {
      display: inline-block;
      border-bottom: vw-sp(2px) dotted #707070;
      &:last-child {
        border-bottom: none;
      }
      .content-news-date {
        width: 100%;
        margin-bottom: vw-sp(10px);
        font-size: vw-sp(30px);
      }
    }
  }
  .info_news .content-news-body {
    width: 100%;
    a {
      font-size: vw-sp(30px);
      font-weight: bold;
      color: #31456A;
      border-bottom: 1px solid #31456A;
      display: inline-block;
      line-height: 1;
      h3 {
        font-weight: bold;
      }
    }
    p {
      margin-bottom: vw-sp(45px);
      margin-top: vw-sp(30px);
      font-size: vw-sp(24px);
    }
  }
  .ItemEvent {
    background: #F5F4ED;
    padding-top: vw-sp(90px);
    .note_introduce {
      font-size: vw-sp(40px);
      span {
        line-height: vw-sp(80px);
      }
    }
    #event {
      border-bottom: none !important;
      margin-bottom: 0;
    }
    .text-column {
      a {
        padding: 2.5vw 3.5vw;
        width: 66.66667vw;
        border-radius: 5px;
        position: relative;
        &:after {
          content: '';
          height: 20px;
          width: 13px;
          background: url(../img/shop/icon_shoplist1.png) no-repeat center center;
          background-size: 100%;
          display: inline-block;
          z-index: 9;
          position: absolute;
          right: 32px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .section-title-ja {
      font-size: vw-sp(30px);
      color: #002040;
      text-align: center;
      margin-top: vw-sp(10px);
    }
  }
  #event{
    margin:17.33vw auto;
  }
  #event .picture-column{
    width:100%;
    float:none;
    margin-bottom:5.33vw;
    img {
      width: 100%;
    }
  }
  #event .text-column{
    width:100%;
    float:none;
    margin-right:0;
    margin-bottom:5.33vw;
    a {
      font-size: vw-sp(30px);
    }
  }
  .showbanner_img{
    text-align: center;
  }
  .showbanner_img img{
    width:100%;
  }
  .showbanner_img a{
    display: inline-block;
    width: 100%;
  }
  #instagram{
    width:90%;
    margin:18vw auto;
  }
  #instagram .section-title-ja{
    margin-bottom:8vw;
  }
  #instagram #instagram-widget.sp{
    width:450px;
    margin:0 auto 13.33vw;
    overflow:hidden;
  }
  #facebook{
    width:90%;
    margin:18vw auto 8vw;
  }
  #facebook .section-title-ja{
    margin-bottom:8vw;
  }
  #facebook #facebook-widget{
    width:100%;
    margin:0 auto;
    margin-bottom:0;
  }
  #top-image #top-logo{
    width: 20%;
  }
  .section-title, .page-title{
    font-size:vw-sp(50px) !important;
    color: #000;
    text-align: center;
  }
  .section-title-ja{
    font-size:vw-sp(30px) !important;
    margin-bottom:6vw !important;
  }
  .ItemEvent .section-title {
    font-size:vw-sp(40px) !important
  }
  .text-column p{
    font-size:4vw;
  }
  .android .text-column p{
    font-size:3vw;
  }
  /*edit index sp*/
  #top #event .section-title.sp,
  #top #facebook.sp .section-title,
  #top #new-item .section-title.sp {
    font-size:45px;
  }
  #top #new-item .picture-column img {
    width: 100%;
  }
}
@include pc {
  #top {
    color: #000;
    text-align: center;
    #top-image{
      .slick-slide{
        img{
          width:100%;
        }
      }
    }
    .content-container {
      width: 100%;
      margin: 0 auto;
    }
    .page-title {
      font-size: vw-pc(35px);
      letter-spacing: 0.2em;

    }
    .introduce_pro {
      padding: vw-pc(100px) 0;
      .content_introduce_pro {
        .page-title {
          padding-bottom: vw-pc(80px);
        }
        p {
          font-size: vw-pc(18px);
          max-width: vw-pc(800px);
          margin: auto;
          line-height: vw-pc(50px);
          letter-spacing: 0.2em;
        }
      }
    }
    .showbanner_img {
      img {
        width: 100%;
      }
      .item_showbanner {
        position: relative;
        margin-bottom: vw-pc(1px);
        .text_show_banner {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          letter-spacing: 0.2em;
          h2{
            text-shadow: 0 vw-pc(2px) vw-pc(4px) rgba(0,0,0,0.5);
          }
          p {
            font-size: vw-pc(14px);
          }
          a {
            font-size: vw-pc(20px);
            letter-spacing: 0.1em;
            min-width: vw-pc(580px);
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: vw-pc(5px);
            background: rgba(0, 0, 0, 0.5);
            display: inline-block;
            padding: vw-pc(25px) 0;
            box-sizing: border-box;
            margin-top: vw-pc(25px);
            position: relative;
            &:after {
              content: '';
              width: vw-pc(12px);
              position: absolute;
              top: 0;
              bottom: 0;
              right: vw-pc(32px);
              left: auto;
              background: url(../img/top/icon_top.png) no-repeat center center;
              background-size: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .banner_img {
        min-height: vw-pc(380px);
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    .info_news {
      max-width: vw-pc(1000px);
      margin: vw-pc(100px) auto;
      .article-list-news {
        padding: 0 vw-pc(100px);
      }
      .content-news-body {
        text-align: left;
        line-height: vw-pc(36px);
      }
      .title_news {
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        padding: vw-pc(10px) 0;
        text-align: center;
        position: relative;
        h2 {
          font-size: vw-pc(20px);
          color: #000;
          font-weight: normal;
        }
        .news_list {
          float: right;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          a {
            font-size: vw-pc(12px);
            box-sizing: border-box;
            color: #000;
            border: 1px solid #707070;
            padding: vw-pc(8px) vw-pc(43px);
            line-height: 1.5;
          }

        }
      }
      .content-news {
        text-align: left;
        padding: vw-pc(42px) 0;
        border-bottom: 1px dotted #707070;
        &:last-child{
          border-bottom:none;
        }
        .content-news-date {
          font-size: vw-pc(12px);
          color: #000;
        }
        h3 {
          font-size: vw-pc(14px);
          display: inline-block;
          text-decoration: underline;
          color: #002040;
        }
        p {
          font-size: vw-pc(12px);
        }
      }
    }
    .ItemEvent {
      margin: 0 auto;
      padding-top:100px;
      background: #F5F4ED;
      #new-item, & #event {
        width: 50%;
        max-width: 50%;
        float: left;
        margin-top: 0;
        padding: 0 vw-pc(100px);
        margin-bottom: 0;
        .picture-column {
          margin-top: vw-pc(25px);
        }
      }
      #new-item {
        border-right: 1px solid #000;
        .picture-column {
          width: 100%;
          float: none;

        }
        .text-column {
          width: 100%;
        }
      }

      #event {
        .picture-column {
          width: 100%;
          float: none;
        }
        .text-column {
          width: 100%;
          margin-right: 0;
        }

      }
      .showbanner_img {
        display: inline-block;
      }
      .section-title {
        font-size: vw-pc(20px);
        color: #000;
      }
      .section-title-ja {
        font-size: vw-pc(14px);
      }
      .btn01 {
        margin-top: vw-pc(29px);
        position: relative;
        height: vw-pc(60px);
        border-radius: vw-pc(5px);
          &:after {
            content: '';
            width: vw-pc(10px);
            position: absolute;
            top: 0;
            bottom: 0;
            right: vw-pc(15px);
            left: auto;
            background: url(../img/top/icon_top.png) no-repeat center center;
            background-size: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

        }
        span{
          height: vw-pc(60px);
          line-height: vw-pc(60px);
          font-size: vw-pc(18px);
        }
      }
      img {
        width: 100%;
      }
    }
    .note_introduce {
      font-size: vw-pc(22px);
      color: #000000;
      margin: vw-pc(90px) 0 vw-pc(15px) 0;
      display: inline-block;
    }
      .linkItem {
        background: #F5F4ED;
        margin: 0;
        display: inline-block;
        width: 100%;
        padding: 100px 0;
        //a {
        //  width: vw-pc(600px);
        //  height:vw-pc(100px);
        //  span{
        //    font-size: vw-pc(25px);
        //    padding:0 0 0 vw-pc(40px);
        //    line-height: vw-pc(100px);
        //    &:before {
        //      width: vw-pc(28px);
        //      height: vw-pc(20px);
        //    }
        //  }
        //
        //  &:after{
        //    width: vw-pc(13px);
        //    height:vw-pc(20px);
        //    right: vw-pc(32px);
        //  }
        //}
      }

    footer{
      margin-top:0;
    }
  }
}

@include ex-pc {
  #top {
    color: #000;
    text-align: center;
    #top-image{
      .slick-slide{
        img{
          width:100%;
        }
      }
    }
    .content-container {
      width: 1000px;
      margin: 0 auto;
    }
    .page-title {
      font-size: 35px;
      letter-spacing: 0.2em;

    }
    .introduce_pro {
      padding: 100px 0;
      .content_introduce_pro {
        .page-title {
          padding-bottom: 80px;
        }
        p {
          font-size: 18px;
          max-width: 800px;
          margin: auto;
          line-height: 50px;
          letter-spacing: 0.2em;
        }
      }
    }
    .showbanner_img {
      img {
        width: 100%;
      }
      .item_showbanner {
        position: relative;
        margin-bottom:1px;
        .text_show_banner {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          letter-spacing: 0.2em;
          h2{
            text-shadow: 0 2px 4px rgba(0,0,0,0.5);
          }
          p {
            font-size: 14px;
          }
          a {
            font-size: 20px;
            letter-spacing: 0.1em;
            min-width: 580px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            background: rgba(0, 0, 0, 0.5);
            display: inline-block;
            padding: 25px 0;
            box-sizing: border-box;
            margin-top: 25px;
            position: relative;
            &:after {
              content: '';
              width: 12px;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 32px;
              left: auto;
              background: url(../img/top/icon_top.png) no-repeat center center;
              background-size: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
      .banner_img {
        min-height: 380px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
    .info_news {
      max-width: 1000px;
      margin: 100px auto 50px;
      .article-list-news {
        padding: 0 100px;
      }
      .content-news-body {
        text-align: left;
        line-height: 36px;
      }
      .title_news {
        border-top: 1px solid #707070;
        border-bottom: 1px solid #707070;
        padding: 10px 0;
        text-align: center;
        position: relative;
        h2 {
          font-size: 20px;
          color: #000;
          font-weight: normal;
        }
        .news_list {
          float: right;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          a {
            font-size: 12px;
            box-sizing: border-box;
            color: #000;
            border: 1px solid #707070;
            padding: 8px 43px;
            line-height: 1.5;
          }

        }
      }
      .content-news {
        text-align: left;
        padding: 42px 0;
        border-bottom: 1px dotted #707070;
        color: #000;
        &:last-child{
          border-bottom:none;
        }
        .content-news-date {
          font-size: 12px;
          color: #000;
        }
        h3 {
          font-size: 14px;
          display: inline-block;
          text-decoration: underline;
          color: #002040;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .ItemEvent {
      margin: 0 auto;
      padding-top:100px;
      background: #F5F4ED;
      #new-item, & #event {
        width: 50%;
        max-width: 50%;
        float: left;
        margin-top: 0;
        padding: 0 100px;
        margin-bottom: 0;
        .picture-column {
          margin-top: 25px;
        }
      }
      #new-item {
        border-right: 1px solid #000;
        .picture-column {
          width: 100%;
          float: none;

        }
        .text-column {
          width: 100%;
        }
      }
      #event {
        .picture-column {
          width: 100%;
          float: none;

        }
        .text-column {
          width: 100%;
          margin-right: 0;
        }
      }
      .showbanner_img {
        display: inline-block;
      }
      .section-title {
        font-size: 20px;
        color: #000;
      }
      .section-title-ja {
        font-size: 14px;
      }
      .btn01 {
        span{
          font-size: 18px;
          line-height: 60px;
          top: auto;
        }
        height: 60px;
        margin-top: 29px;
        position: relative;
        border-radius: 5px;
        &:after {
          content: '';
          width: 10px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          left: auto;
          background: url(../img/top/icon_top.png) no-repeat center center;
          background-size: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      img {
        width: 100%;
      }
    }
    .note_introduce {
      font-size: 22px;
      color: #000000;
      margin: 90px 0 15px 0;
      display: inline-block;
    }
    .linkItem{
      background: #F5F4ED;
      margin: 0;
      display: inline-block;
      width: 100%;
      padding: 100px 0;

    }
    footer{
      margin-top:0;
    }
  }

}
