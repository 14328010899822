/*
======================
define variable
======================
*/

/*
======================
define font-family
======================
*/



/*
======================
define font-size
======================
*/



/*
======================
define color
======================
*/



