/*
==================
define common css
==================
*/
@import "function";
@import "normalize";
@import "variable";
@import "mixin";

.only_sp{
  display: block!important;
  @include pc{
    display: none!important;
  }
  @include ex-pc{
    display: none!important;
  }
}

.only_pc{
  display: none!important;
  @include pc{
    display: block!important;
  }
  @include ex-pc{
    display: block!important;
  }
}

.clearfix{
  &:after{
    content: '';
    display: block;
    clear: both;
  }
}

.main-image-list img {
  width: 100%;
}

body{
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  font-size: vw-sp(20px);
  font-weight: 400;
  color: #000;
  @include pc{
    font-size:vw-pc(20px);
  }
  @include ex-pc{
    font-size: 20px;
  }
}

// Style css sake //

@include pc-full {
  .breadcrumb {
    right: 0;
    bottom: 0;
    background: #31456A;
    color: #fff;
    text-align: center;
    left: 0;
    padding: 45px 0;
  }
  .breadcrumb h2 {
    font-size: vw-pc_full(30px);
    color: #fff;
    letter-spacing: 0.4em;
    font-weight: normal;
  }
  .breadcrumb .text_small {
    //font-size: vw-pc_full(20px);
    font-size: 0.78125vw;
    color: #fff;
    letter-spacing: 0.4em;
    font-family: "Hiragino Mincho ProN";
    text-transform: capitalize;
  }
}

@include ex-pc {
  //.breadcrumb {
  //  padding: 100px 0;
  //}
  //.breadcrumb h2 {
  //  font-size: 40px;
  //}
  //.breadcrumb .text_small {
  //  font-size: 20px;
  //}
}

@include mobile() {
  .breadcrumb {
    right: 0;
    bottom: 0;
    background: #31456A;
    color: #fff;
    text-align: center;
    left: 0;
    padding: 40px 0;
  }
  .breadcrumb {
    padding: 4.66667vw 0;
    h2 {
      font-size: 5.33333vw;
      letter-spacing: 0.41em;
      font-weight: normal;
    }
    .text_small {
      font-size: 10px;
      display: inline-block;
      letter-spacing: 8.5px;
      font-family: "Hiragino Mincho ProN";
      text-transform: capitalize;
    }
  }
}

// style css for new header footer
@include pc{
  header{
    display: block;
    text-align: center;
    padding: 25px 0;
    position: static !important;
    &.position_fixed{
      position: fixed !important;
    }
    .title_header{
      width: 100%;
      h1{
        display: inline-block;
      }
      p{
        padding: vw-pc(15px) 0 vw-pc(30px);
        span{
          font-size: vw-pc(12px);
          font-weight: bold;
        }
        span:first-child{
          padding-right:20px;
        }
      }
    }
    #global-navi{
      justify-content: center;
    }
    #global-navi-wrapper{
      ul{
        li{
          margin: 0;
          font-size: vw-pc(20px);
          width:calc( 100% /7);
          &.medium_text{
            font-size: vw-pc(30px);

            font-weight: bold;
          }
          &:last-child{
            display: inline-flex;
            a:first-child{
              padding-right:vw-pc(10px);
            }
          }
          &:first-child{
            padding-left: 0;
          }
        }
      }
    }
    &.position_fixed{
      .title_header{
        display:none;
      }
    }
  }
  footer{
    a,span,li{
      color: #fff;
      font-size: 14px;
    }
    #global-navi{
      justify-content: center;
      .social {
        width: vw-pc(20px) !important;
      }
      > li {
        padding: 0 vw-pc(5px);
      }
    }
    #footer-logo{
      display: inline-block;
      text-align: center;
      width: 100%;
      max-width: 100%;
      margin-top:65px;
    }
    #items_text{
      max-width: 708px;
    }
    #footer-logo{
      p{
        padding: vw-pc(35px);
        span{
          font-size: vw-pc(20px);
          font-weight: bold;
        }
        span:first-child{
          padding-right:20px;
        }
      }
      img{
        width: auto;
      }
    }
    .items_text {
      max-width: 825px;
      margin:0 auto 50px auto;
      li{
        font-size: 15px;
        list-style: none;
        letter-spacing: 0;
      }
    }
    #copyright{
      font-size: 14px;
    }

  }
  .linkItem{
    .btn02.pc{
      img
      {
        width: 100%;
      }
    }
  }

}

