
/*******************************************************************
 
 define function()

 ******************************************************************/

@function vw-sp($object) {
  $vw: (750 * 0.01) * 1px;
  @return ($object / $vw) * 1vw;
}

@function vw-pc($object) {
  $vw: (1000 * 0.01) * 1px;
  @return ($object / $vw) * 1vw;
}
@function vw-pc_full($object) {
  $vw: (1920 * 0.01) * 1px;
  @return ($object / $vw) * 1vw;
}
