/*******************************************************************
 
 define breakpoint

 ******************************************************************/


 $mobile: "only screen and (max-width: 768px)";
 $pc: "only screen and (min-width: 768.1px) and (max-width: 1000px)";
  $pc-full: "only screen and (min-width: 768.1px)";
 $ex-pc: "only screen and (min-width: 1000px)";
$ex-pc-full: "only screen and (min-width: 1920px)";


 @mixin mobile() {
   @media #{$mobile} {
     @content;
   }
 }

 @mixin pc() {
   @media #{$pc} {
     @content;
   }
 }
@mixin pc-full() {
  @media #{$pc-full} {
    @content;
  }
}
@mixin ex-pc-full() {
  @media #{$ex-pc-full} {
    @content;
  }
}
 @mixin ex-pc() {
   @media #{$ex-pc} {
     @content;
   }
 }

